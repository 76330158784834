.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: auto;
  max-width: 384px;
  padding: var(--space-8x) var(--space-3x);
  text-align: center;
  width: 100%;
}
